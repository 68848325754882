// @flow
import style from "./style.module.scss";

import React, { type Node, useState, useEffect } from "react";
import _Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

import Head from "./Head";
import rowRender from "./rowRenderer";
import LoadingBox from "../LoadingBox";
import Typography from "../Typography";
import { isArrayWithContent } from "@utils";

const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to === -1 ? count : to} van ${
        count !== -1 ? count : `meer dan ${to}`
    }`;

const ROW_TRESHOLD_LIMIT = 400;
const OPTION_DEFAULTS = [5, 10, 25, 30, 40];

type Props = {
    id: string,
    rows: ?Array<*>,
    definition: Array<*>,
    sort?: ?string,
    onSort?: (param: ?string) => void,
    onFilter?: (*) => void,
    activeFilters?: { [string]: * },
    type?: string,
    rowRenderer: string,
    selection?: ?number | ?(number[]),
    loading?: boolean,
    noData?: string | Node,
    tooManyResults?: string | Node,
    canSelectUP?: boolean,
    isRedirect?: boolean,

    // callbacks
    onSelect?: (record: *, index: *) => void,
    onSelectAll?: (records: ?Array<*>) => void,
    onDetails?: (record: *, index: *) => void,
    onDownloadFile?: (
        filenameWithExtention: string,
        documentId: string,
    ) => void,
    onUnlink?: (record: *) => void,
    onSendReport?: (record: *) => void,
    onDownloadAttachment?: (file: *) => void,
    onStructureDetails?: (record: *) => void,
    onTreeToggle?: *,
    onInspectionPointSelect?: (record: *) => void,
    onEdit?: (record: *) => void,
    onArchive?: (record: *) => void,
    onMarkReportAsReady?: (record: *) => void,
    onDelete?: (item: *) => void,
    onAdd?: (record: *) => void,
    onMarkReportAsSend?: (record: *) => void,
    onReplace?: (record: *) => void,
    onChangeMainVisit?: (inspectionId: *) => void,
    /**pagination*/
    onChangeRowsPerPage?: (ev: *) => void,
    onChangePage?: (ev: *, page: number) => void,
    totalRows?: number,
    rowsPerPage?: *,
    page?: number,
    hidePagination?: boolean,
    treeInfo?: *,
    treeLoading?: boolean,
    treeData?: *,
    inspectionPointChildren?: *,
    injectChildren?: (node: *, path: *) => void,
    userId?: *,
    flat?: boolean,
    hasPermissionToPublishReport?: boolean,
    hasPermissionToSendReport?: boolean,
    hasPermissionToCombineAssignment?: *,
    canEditExemptions?: boolean,
    canEliminateInfringements?: boolean,
    hasPermissionToDetails?: boolean,
    hasPermissionToRemoveReport?: boolean,
    flow?: string,
    referenceDates?: *,
    mustHaveFilters?: boolean,
    enableLoadAllData?: boolean,
    canActivateVersions?: boolean,
    canDeactivateVersions?: boolean,
    canCancelVersions?: boolean,
    tableContainerStyle?: *,
    stickyHeader?: boolean,
};

/**
 * Table
 */
const Table = ({
    id,
    rows,
    definition,
    onSort,
    sort,
    onSelectAll,
    onFilter,
    rowRenderer,
    // callbacks
    onSelect,
    onDetails,
    onDownloadFile,
    onSendReport,
    onUnlink,
    onDownloadAttachment,
    onStructureDetails,
    onInspectionPointSelect,
    onTreeToggle,
    onEdit,
    onArchive,
    onDelete,
    onMarkReportAsSend,
    onReplace,
    onChangeMainVisit,
    onAdd,
    //pagination
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    totalRows,
    //other
    selection,
    canSelectUP = false,
    type,
    activeFilters,
    loading,
    noData,
    tooManyResults,
    hidePagination,
    treeInfo,
    treeData,
    treeLoading,
    inspectionPointChildren,
    injectChildren,
    userId,
    flat,
    hasPermissionToPublishReport,
    hasPermissionToSendReport,
    hasPermissionToCombineAssignment,
    canEditExemptions,
    canEliminateInfringements,
    hasPermissionToDetails,
    hasPermissionToRemoveReport,
    flow,
    referenceDates,
    mustHaveFilters = false,
    isRedirect,
    enableLoadAllData = false,
    canActivateVersions,
    canDeactivateVersions,
    canCancelVersions,
    tableContainerStyle,
    stickyHeader = false,
}: Props) => {
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
        OPTION_DEFAULTS,
    );

    useEffect(() => {
        if (enableLoadAllData) {
            setRowsPerPageOptions(
                OPTION_DEFAULTS.concat({
                    label: `Max (${ROW_TRESHOLD_LIMIT}) laden`,
                    value:
                        totalRows && totalRows < ROW_TRESHOLD_LIMIT
                            ? -1
                            : ROW_TRESHOLD_LIMIT,
                }),
            );
        } else {
            setRowsPerPageOptions(OPTION_DEFAULTS);
        }
    }, [enableLoadAllData, totalRows]);

    const getNoDataMessage = () => {
        if (!!noData && typeof noData === "string") return noData;
        if (
            mustHaveFilters &&
            !(activeFilters && Object.keys(activeFilters).length > 0)
        )
            return "Gebruik de filters om resultaten weer te geven";
        else if (!noData)
            return activeFilters && Object.keys(activeFilters).length > 0
                ? "Geen resultaten voor deze filters"
                : "Geen resultaten";
    };

    const noRows = !isArrayWithContent(rows) && !loading;
    const allSelected = //$FlowFixMe
        isArrayWithContent(selection) &&
        isArrayWithContent(rows) && //$FlowFixMe
        selection.length === rows.length;

    /**
     * Render
     */
    return (
        <Paper elevation={flat && 0}>
            <div className={style.tableWrap}>
                <TableContainer style={{ ...tableContainerStyle }}>
                    <_Table
                        aria-labelledby="tableTitle"
                        size="medium"
                        id={`${id}-table`}
                        stickyHeader={stickyHeader}
                    >
                        <Head
                            definition={definition}
                            id={id}
                            onSort={onSort}
                            sort={sort}
                            type={type}
                            onFilter={onFilter}
                            activeFilters={activeFilters}
                            onSelectAll={() => onSelectAll && onSelectAll(rows)}
                            allSelected={allSelected}
                            indeterminate={
                                //$FlowFixMe
                                isArrayWithContent(selection) && !allSelected
                            }
                        />
                        <TableBody>
                            {!loading && tooManyResults && (
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={definition.length}
                                    >
                                        <Typography
                                            type="headline6Regular"
                                            id={`${id}-lblNoData`}
                                        >
                                            {tooManyResults}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {isArrayWithContent(rows) && // $FlowFixMe
                                rowRender({
                                    type: rowRenderer,
                                    id,
                                    data: rows,
                                    selection,
                                    treeInfo,
                                    treeLoading,
                                    treeData,
                                    inspectionPointChildren,
                                    definition,
                                    userId,
                                    hasPermissionToPublishReport,
                                    hasPermissionToSendReport,
                                    hasPermissionToCombineAssignment,
                                    hasPermissionToRemoveReport,
                                    canEditExemptions,
                                    canEliminateInfringements,
                                    hasPermissionToDetails,
                                    referenceDates,
                                    callbacks: {
                                        onRowSelect: onSelect,
                                        onDetails: onDetails,
                                        onStructureDetails: onStructureDetails,
                                        onDownloadFile: onDownloadFile,
                                        onSendReport: onSendReport,
                                        onChangeMainVisit: onChangeMainVisit,
                                        onUnlink: onUnlink,
                                        onTreeToggle: onTreeToggle,
                                        // loadInspectionPointChildren: loadInspectionPointChildren,
                                        injectChildren: injectChildren,
                                        onInspectionPointSelect: onInspectionPointSelect,
                                        onDownloadAttachment,
                                        onEdit,
                                        onArchive,
                                        onDelete,
                                        onAdd,
                                        onMarkReportAsSend,
                                        onReplace,
                                    },
                                    flow,
                                    canSelectUP,
                                    isRedirect,
                                    canActivateVersions,
                                    canDeactivateVersions,
                                    canCancelVersions,
                                })}
                            {(!rows || rows.length === 0 || loading) && (
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={definition.length}
                                    >
                                        {noRows &&
                                            !!noData &&
                                            typeof noData !== "string" &&
                                            noData}
                                        {noRows && (
                                            <Typography
                                                type="headline5"
                                                id={`${id}-lblNoData`}
                                            >
                                                {getNoDataMessage()}
                                            </Typography>
                                        )}
                                        {loading && <LoadingBox />}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </_Table>
                </TableContainer>
            </div>
            {!hidePagination && !noRows && (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalRows || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    labelRowsPerPage="Rijen per pagina:"
                    labelDisplayedRows={labelDisplayedRows}
                    backIconButtonText="Vorige pagina"
                    nextIconButtonText="Volgende pagina"
                    id={`${id}-table-pagination`}
                    backIconButtonProps={{
                        id: `${id}-backIconButton`,
                        color: "secondary",
                    }}
                    nextIconButtonProps={{
                        id: `${id}-nextIconButton`,
                        color: "secondary",
                    }}
                    SelectProps={{ id: `${id}-rowsPerPageSelect` }}
                />
            )}
        </Paper>
    );
};

export default Table;
