//@flow
import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";

// Mui components
import Box from "@material-ui/core/Box";

// Own components & utils
import Table from "@components/Shared/Table";
import { Typography } from "@components/Shared";
import { editExternalPersons as editExternalPersonsDefinition } from "../../../definitions";
import { ROWRENDERERCONST } from "@constants";
import { useFilters } from "@hooks";
import { buildQueryForTableFilters } from "@utils";

/**
 * Redux stuff
 * */
// Redux stuff
import { clear as clearFeUsers } from "@stores/advanced-search-fe-users";
import { load as getFeUsers } from "@stores/advanced-search-fe-users";

const personsSelector = state => state.advancedSearchFeUsers;

/**
 * Props type
 */
type Props = {
    id: string,
};

/*
 * Component
 */
const ExternalPersons = ({ id }: Props) => {
    const [filters, , handleTableFiltersChange] = useFilters(location.pathname);
    const [sort, setSort] = useState<string | null | void>(undefined);
    const storedRowsPerPage = localStorage.getItem("adminExternalPersonsRows");
    const isFullyMountedRef = useRef(false);
    const [pagination, setPagination] = useState({
        size: (storedRowsPerPage && parseInt(storedRowsPerPage)) || 25,
        page: 0,
    });
    const dispatch = useDispatch();
    const externalPersonsStore = useSelector(personsSelector);
    const persons = externalPersonsStore.data?.content;
    const totalPersons = externalPersonsStore.data?.totalElements;

    useEffect(() => {
        dispatch(getFeUsers({ path: { page: 0, size: 25 } }, true));
    }, [dispatch]);

    useEffect(() => {
        if (!isFullyMountedRef.current) {
            isFullyMountedRef.current = true;
            return;
        }

        dispatch(clearFeUsers());
        let query =
            buildQueryForTableFilters(filters, "adminEditExternalUsers") || {};
        if (sort) query.sort = sort;

        dispatch(
            getFeUsers(
                {
                    path: {
                        query: query,
                        page: pagination.page,
                        size: pagination?.size ? pagination.size : 25,
                    },
                },
                true,
            ),
        );
    }, [pagination, filters, isFullyMountedRef]);

    const handleChangeRowsPerPage = event => {
        localStorage.setItem("adminEditExternalUsersRows", event.target.value);
        setPagination({ page: 0, size: event.target.value });
    };

    const handleSortChange = useCallback(
        (update: *) => {
            setPagination({
                page: 0,
                size: pagination?.size ? pagination.size : 25,
            });
            setSort(update);
        },
        [setPagination, setSort],
    );

    return (
        <Fragment>
            <Typography type="headline5">Externe personen</Typography>
            <Box mt={5}>
                <Table
                    color="primary"
                    id={`${id}-external-persons-table`}
                    loading={externalPersonsStore.loading}
                    rows={persons || []}
                    noData="Geen externe personen gevonden."
                    definition={editExternalPersonsDefinition}
                    type="adminEditExternalPersons"
                    rowRenderer={ROWRENDERERCONST.EDIT_EXTERNAL_PERSONS}
                    sort={sort}
                    onSort={handleSortChange}
                    activeFilters={filters?.tableFilters}
                    onFilter={handleTableFiltersChange}
                    page={pagination.page}
                    onChangePage={(event, page) =>
                        setPagination({ ...pagination, page })
                    }
                    rowsPerPage={pagination.size}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    totalRows={totalPersons || 0}
                />
            </Box>
        </Fragment>
    );
};

export default ExternalPersons;
