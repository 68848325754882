// @flow
import React, {
    Fragment,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from "react";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import types
import type { Notification, ApiStore } from "@types";

// import components
import {
    Typography,
    Redirect,
    Modal,
    SimpleExpansionPanel,
    LoadingButton,
    TextEditor,
} from "@components/Shared";
import AddReaction from "./AddReaction";
import ReactionsPreview from "./ReactionsPreview";

// import constants
import { ASSIGNMENTS, REDIRECTIONS, NOTIFICATIONS } from "@constants";
import { constants as draftChangesConstants } from "@stores/draft-changes";
import { constants as endReactionPeriodConstants } from "@stores/end-reaction-period";

// utils
import { isArrayWithContent } from "@utils";
import { usePermission, useMember, useUserFunction } from "@hooks";

const FIELDS_INITIAL = ASSIGNMENTS.REACTIES_FIELDS.reduce(
    (obj, field) => ({ ...obj, [field.key]: false }),
    {},
);

const CAN_PROCESS_STATUSES = [
    ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
    ASSIGNMENTS.STATUSSES.REOPENED,
    ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
    ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
];

const CAN_ADD_EDIT_STATUSES = [
    ASSIGNMENTS.STATUSSES.DRAFT_REPORT_PUBLISHED,
    ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
    ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
    ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
    ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
    ASSIGNMENTS.STATUSSES.FINAL_REPORT_CREATED,
    ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
    ASSIGNMENTS.STATUSSES.FINAL_REPORT_PUBLISHED,
    ASSIGNMENTS.STATUSSES.CLOSED,
    ASSIGNMENTS.STATUSSES.REOPENED,
];

const hasNoReactionFilter = (reactions: *) => (el: *) => {
    const hasNoReaction = reactions.every(
        reaction =>
            !(
                reaction.senderType === el.senderType &&
                reaction.senderEmail === el.senderEmail
            ) || reaction.type === "DEADLINE_EXTENSION_REQUESTED",
    );
    return hasNoReaction;
};

const composeListOfComplainers = (assignment: *, reactions: *) => {
    if (!assignment) return [];
    if (!isArrayWithContent(assignment.inspectionRequests)) return [];
    const listOfRequests = assignment.inspectionRequests
        .filter((el: *) => el.complaint === true)
        .filter(
            (el: *) =>
                el.anonymity === "NONE" || el.anonymity === "INSPECTION_POINT",
        );

    const listOfComplainers = listOfRequests.map((el: *) => ({
        senderType: `Klachtindiener ${el.submitterFirstName} ${el.submitterLastName} - ${el.reference}`,
        senderEmail: el.submitterEmailAddress,
    }));

    if (!isArrayWithContent(listOfComplainers)) return [];

    return listOfComplainers.filter(hasNoReactionFilter(reactions));
};

const composeListOfSenderOptions = (
    reactions: *,
    complainers: *,
    complaint: *,
) => {
    const baseOptions = [
        { senderType: "Inspectiepunt / Inrichtende Macht", senderEmail: "" },
        //{ senderType: "Inspectiepunt", senderEmail: "" },
    ];

    if (complaint)
        baseOptions.push({ senderType: "Klachtindiener", senderEmail: "" });
    const filtered = baseOptions.filter(hasNoReactionFilter(reactions));
    return filtered.concat(complainers);
};

/**
 * Props type
 */
type Props = {
    id: string,
    assignment: *,
    //contactPersons: *,
    addReaction: *,
    loadReactions: (id: string) => Promise<*>,
    processingReactions: (
        inspectionId: string,
        operation: string,
        path: string,
        newValue: string,
    ) => Promise<any>,
    createFinalReport: (inspectionId: string) => Promise<any>,
    notify: Notification => void,
    reactions: Array<*>,
    updateReaction: (
        inspectionId: string,
        reactionId: string,
        data: *,
    ) => Promise<*>,
    statusLoading: boolean,
    //upload props
    uploadDocuments: (files: Array<*>) => void,
    updateFiles: (files: *) => void,
    uploaderValue: *,
    uploaderLoading: boolean,
    downloadDocument: (file: *) => void,
    deleteAttachment: (
        inspectionId: string,
        reactioId: string,
        file: *,
    ) => void,
    clearAttachments: () => void,
    loadChangedModules: (inspectionId: string) => Promise<*>,
    draftChanges: ApiStore<string[]>,
    endReactionPeriod: (inspectionId: string) => Promise<*>,
    loadInspection: (inspectionId: string) => Promise<*>,
    deleteReaction: (inspectionId: string, reactioId: string) => void,
    addReactionLoading?: boolean,
    updateReactionLoading?: boolean,
    processReactionLoading: boolean,
    endReactionPeriodLoading: boolean,
    processReaction: (
        inspectionId: string,
        reactionId: string,
        data: *,
    ) => Promise<*>,
};

/**
 * Reaction
 */
const Reaction = ({
    id,
    assignment,
    //contactPersons,
    addReaction,
    loadReactions,
    processingReactions,
    createFinalReport,
    notify,
    reactions,
    updateReaction,
    statusLoading,
    uploadDocuments,
    updateFiles,
    uploaderValue,
    uploaderLoading,
    downloadDocument,
    deleteAttachment,
    clearAttachments,
    loadChangedModules,
    draftChanges,
    endReactionPeriod,
    loadInspection,
    deleteReaction,
    addReactionLoading,
    updateReactionLoading,
    processReactionLoading,
    endReactionPeriodLoading,
    processReaction,
}: Props) => {
    /**
     * Permissions
     */
    const hasPermissionToCRUDReaction = usePermission("reaction.crud.write");
    const hasPermissionToStopReaction = usePermission(
        "reaction.stopReaction.write",
    );
    const hasPermissionToProcessReaction = usePermission(
        "reaction.process.write",
    );
    const userIsMemberOfAssignment = useMember();
    const isLeadInspector = useUserFunction("leadInspector");

    /**
     * States
     */
    const [selectedCard, setSelectedCard] = useState(-1);
    const [change, setChange] = useState(FIELDS_INITIAL);
    const [showModal, toggleModal] = useState(false);
    const [comment, setComment] = useState("");
    const [reactionToEdit, setReactionToEdit] = useState(undefined);
    const [renderRTE, setRenderRTE] = useState(false);

    const canCRUDReaction =
        (hasPermissionToCRUDReaction || isLeadInspector) &&
        CAN_ADD_EDIT_STATUSES.includes(assignment?.status);

    const canProcessReaction =
        (hasPermissionToProcessReaction || userIsMemberOfAssignment) &&
        CAN_PROCESS_STATUSES.includes(assignment?.status);

    const complainers = useMemo(
        () => composeListOfComplainers(assignment, reactions),
        [assignment, reactions],
    );

    const senderOptions = useMemo(
        () =>
            composeListOfSenderOptions(
                reactions,
                complainers,
                assignment?.complaint,
            ),
        [reactions, complainers, assignment?.reasons],
    );

    /**
     * Loaders
     */
    useEffect(() => {
        loadData();
    }, []);

    const loadData = useCallback(() => {
        assignment?.inspectionId && loadReactions(assignment.inspectionId);
        clearAttachments();
    }, [loadReactions, clearAttachments, assignment?.inspectionId]);

    /**
     *  Processeing responses
     */

    const generateFinalReport = () => {
        const message = "Definitief verslag aangemaakt";
        createFinalReport(assignment.inspectionId).then(response => {
            if (response) {
                notify({
                    type: NOTIFICATIONS.TYPE.MODAL,
                    message,
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    primaryAction: () =>
                        navigate("/opdrachten/opdrachten-team"),
                    primaryActionText: "Naar overzicht",
                    secondaryActionText: "Ok",
                    secondaryAction: loadData,
                });
            }
        });
    };

    /**
     *  Processeing responses
     */
    const responsesProcessed = (status: string) => {
        const message = "Alle reacties zijn verwerkt";
        processingReactions(
            assignment.inspectionId,
            "replace",
            "/status",
            status,
        ).then(response => {
            if (response) {
                notify({
                    type: NOTIFICATIONS.TYPE.MODAL,
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message,
                    primaryAction: () =>
                        navigate("/opdrachten/opdrachten-team"),
                    primaryActionText: "Naar overzicht",
                    secondaryAction: loadData,
                    secondaryActionText: "Ok",
                });
            }
        });
    };

    const openModal = () => {
        toggleModal(true);
        setChange(FIELDS_INITIAL);
        setComment("");

        loadChangedModules(assignment.inspectionId).then(response => {
            if (
                (response || response.type === draftChangesConstants.SUCCESS) &&
                isArrayWithContent(response.payload)
            )
                setComment(response.payload.join(", "));

            setRenderRTE(true);
            return;
        });
    };

    const onPreviewCardBtnClick = (action: string) => {
        if (action === "edit") setReactionToEdit(reactions[selectedCard]);
        if (action === "delete") onReactionDelete();
        if (action === "processed") openModal();
    };
    const closeModal = () => toggleModal(false);

    /**
     * Process handler
     */
    const handleProcess = (change: *, comment: string) => {
        const data: any = {
            change,
        };
        if (comment !== "") data.comment = comment;
        processReaction(
            assignment.inspectionId,
            reactions[selectedCard].id,
            data,
        ).then((response: *) => response && toggleModal(false));
    };

    /**
     * Download attachment
     */

    const onDocumentDownload = (file: *) => downloadDocument(file);

    /**
     * Delete attachment
     * From BE
     */
    const onAttachmentDelete = (reactionId: string, file: *) => {
        deleteAttachment(assignment.inspectionId, reactionId, file);
    };

    /**
     * Delete Reaction
     */
    const onReactionDelete = () => {
        notify({
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            type: NOTIFICATIONS.TYPE.MODAL,
            message: "Bent u zeker dat u de reactie wilt verwijderen?",
            primaryActionText: "Ja",
            primaryAction: () =>
                deleteReaction(
                    assignment.inspectionId,
                    reactions[selectedCard].id,
                ),
            secondaryActionText: "Nee",
        });
    };

    const handleFieldCheck = (ev: *) => {
        const { name, checked } = ev.target;
        if (name === "noChange" && checked) {
            setChange({ ...FIELDS_INITIAL, [name]: checked });
        } else {
            setChange({ ...change, [name]: checked });
        }
    };

    const isFieldDisabled = (key: string) => {
        if (key !== "noChange" && change.noChange) return true;
        return false;
    };

    const submitProcessDisabled = change.noChange
        ? false
        : !comment || Object.keys(change).every(key => !change[key]);

    const onEndReactionPeriod = () => {
        const action = () => {
            endReactionPeriod(assignment.inspectionId).then(response => {
                if (
                    !response ||
                    response.type !== endReactionPeriodConstants.SUCCESS
                )
                    return;

                loadInspection && loadInspection(assignment.inspectionId);
                notify({
                    type: NOTIFICATIONS.TYPE.MODAL,
                    message: "Reactietermijn werd afgesloten.",
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    primaryAction: () =>
                        navigate("/opdrachten/opdrachten-team"),
                    primaryActionText: "Naar opdrachtenoverzicht",
                    secondaryActionText: "Ok",
                    secondaryAction: loadData,
                });
            });
        };

        notify({
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            type: NOTIFICATIONS.TYPE.MODAL,
            message: "Bent u zeker dat u de reactietermijn wilt stoppen?",
            primaryActionText: "Ja",
            primaryAction: action,
            secondaryActionText: "Nee",
        });
    };

    const showEndReactionPeriod =
        assignment?.status === "DRAFT_REPORT_PUBLISHED";

    /**
     * Render
     */

    return (
        <Fragment>
            <Modal
                isOpen={!!reactionToEdit}
                id={`${id}-edit-reaction`}
                ariaDescribedBy={`{${id}-modal-content}`}
                title="Bewerken reactie"
            >
                <AddReaction
                    id={id}
                    uploaderValue={uploaderValue}
                    clearAttachments={clearAttachments}
                    inspectionId={assignment?.inspectionId}
                    onSubmit={updateReaction}
                    uploadDocuments={uploadDocuments}
                    updateFiles={updateFiles}
                    uploaderLoading={uploaderLoading}
                    reaction={reactionToEdit}
                    onEditModalClose={() => setReactionToEdit(undefined)}
                    loading={updateReactionLoading}
                    senderOptions={senderOptions}
                    embedded
                />
            </Modal>
            {!!reactions[selectedCard] && (
                <Modal
                    isOpen={showModal}
                    id={`${id}-processing-modal`}
                    ariaDescribedBy={`{${id}-modal-content}`}
                    title="Verwerk reactie"
                >
                    <Fragment>
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="center"
                            flexDirection="column"
                            p={3}
                        >
                            {reactions[selectedCard].type ===
                            "CHANGE_REQUESTED" ? (
                                <Fragment>
                                    <Box mb={1}>
                                        <Typography type="subtitle1">
                                            Welke gegevens werden aangepast?
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        flexWrap="wrap"
                                    >
                                        {ASSIGNMENTS.REACTIES_FIELDS.map(
                                            ({ key, label }) => (
                                                <FormControlLabel
                                                    key={key}
                                                    id={`${id}-mdlProcessing-option-${key}`}
                                                    disabled={isFieldDisabled(
                                                        key,
                                                    )}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                change[key]
                                                            }
                                                            onChange={ev =>
                                                                handleFieldCheck(
                                                                    ev,
                                                                )
                                                            }
                                                            name={key}
                                                        />
                                                    }
                                                    label={label}
                                                />
                                            ),
                                        )}
                                    </Box>
                                    <Box mb={1}>
                                        <Typography type="subtitle1">
                                            {`Omschrijving van de aanpassing ${
                                                change.noChange
                                                    ? "(optioneel)"
                                                    : ""
                                            }`}
                                            {draftChanges.loading && (
                                                <CircularProgress size={26} />
                                            )}
                                        </Typography>
                                    </Box>
                                    {renderRTE && (
                                        <Box width={"100%"}>
                                            <TextEditor
                                                id="outlined-multiline-static"
                                                content={comment}
                                                getHTML
                                                onChange={content =>
                                                    setComment(
                                                        content ? content : "",
                                                    )
                                                }
                                            />
                                        </Box>
                                    )}
                                </Fragment>
                            ) : (
                                <Box>
                                    <Typography type="body1">
                                        De streefdatum zal aangepast worden
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box
                            mt={2}
                            display="flex"
                            justifyContent="flex-end"
                            justifyItems="center"
                        >
                            <Box mr={2}>
                                <Button
                                    id={`${id}-modal-button-secondary`}
                                    color="primary"
                                    onClick={() => closeModal()}
                                >
                                    Annuleer
                                </Button>
                            </Box>
                            <LoadingButton
                                id={`${id}-modal-button-primary`}
                                variant="contained"
                                color="primary"
                                disabled={submitProcessDisabled}
                                disableElevation
                                loading={processReactionLoading}
                                onClick={() => handleProcess(change, comment)}
                            >
                                Verwerkt
                            </LoadingButton>
                        </Box>
                    </Fragment>
                </Modal>
            )}
            {assignment?.inspectionPoint ? (
                <Fragment>
                    {canCRUDReaction ? (
                        <SimpleExpansionPanel
                            labelId={`${id}-informatie-van-de-opdracht`}
                            id={`${id}-informatie-van-de-opdracht`}
                            titleType="headline5"
                            title="Voeg reacties toe"
                            defaultExpanded
                        >
                            <AddReaction
                                id={id}
                                uploaderValue={uploaderValue}
                                clearAttachments={clearAttachments}
                                inspectionId={assignment?.inspectionId}
                                onSubmit={addReaction}
                                uploadDocuments={uploadDocuments}
                                updateFiles={updateFiles}
                                uploaderLoading={uploaderLoading}
                                loading={addReactionLoading}
                                senderOptions={senderOptions}
                            />
                        </SimpleExpansionPanel>
                    ) : (
                        <Box display="flex" justifyContent="center">
                            <Typography type="headline5">
                                In deze stap van de workflow kunnen er geen
                                reacties worden toegevoegd
                            </Typography>
                        </Box>
                    )}
                    {hasPermissionToStopReaction && showEndReactionPeriod && (
                        <Box display="flex" justifyContent="flex-end" my={2}>
                            <LoadingButton
                                id={`${id}-end-reaction-period`}
                                variant="contained"
                                color="primary"
                                onClick={onEndReactionPeriod}
                                loading={endReactionPeriodLoading}
                            >
                                Reactietermijn stoppen
                            </LoadingButton>
                        </Box>
                    )}
                    {!isArrayWithContent(reactions) && (
                        <Box display="flex" justifyContent="center">
                            <Typography type="headline5">
                                Er zijn nog geen reacties voor deze opdracht.
                            </Typography>
                        </Box>
                    )}
                    {isArrayWithContent(reactions) && (
                        <ReactionsPreview
                            id={id}
                            statusLoading={statusLoading}
                            selectedCard={selectedCard}
                            setSelectedCard={index => setSelectedCard(index)}
                            reactions={reactions}
                            responsesProcessed={responsesProcessed}
                            generateFinalReport={generateFinalReport}
                            onBtnClick={onPreviewCardBtnClick}
                            downloadDocument={onDocumentDownload}
                            onAttachmentDelete={onAttachmentDelete}
                            reactionDeadline={assignment?.reactionDeadline}
                            canProcessReaction={canProcessReaction}
                            canEditReaction={canCRUDReaction}
                        />
                    )}
                </Fragment>
            ) : (
                <Redirect data={REDIRECTIONS.REACTIONS} />
            )}
        </Fragment>
    );
};

export default Reaction;
