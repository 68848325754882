//@flow
import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";

// Mui components
import Box from "@material-ui/core/Box";

// Own components & utils
import Table from "@components/Shared/Table";
import { Typography } from "@components/Shared";
import { InternalPersonsModal } from "@components/Admin";
import { editInternalPersons as editInternalPersonsDefinition } from "../../../definitions";
import { ROWRENDERERCONST } from "@constants";
import { useFilters } from "@hooks";
import { buildQueryForTableFilters } from "@utils";

/**
 * Redux stuff
 * */
import { load as getPersons } from "@stores/advanced-search-persons";
import { clear as clearPersons } from "@stores/advanced-search-persons";
const personsSelector = state => state.advancedSearchPersons;

/**
 * Props type
 */
type Props = {
    id: string,
};

/*
 * Component
 */
const InternalPersons = ({ id }: Props) => {
    const [filters, , handleTableFiltersChange] = useFilters(location.pathname);
    const [sort, setSort] = useState<string | null | void>(undefined);
    const [
        showEditInternalPersonModal,
        setShowEditInternalPersonModal,
    ] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const storedRowsPerPage = localStorage.getItem("adminInternalPersonsRows");
    const isFullyMountedRef = useRef(false);
    const [pagination, setPagination] = useState({
        size: (storedRowsPerPage && parseInt(storedRowsPerPage)) || 25,
        page: 0,
    });
    const dispatch = useDispatch();
    const internalPersonsStore = useSelector(personsSelector);
    const persons = internalPersonsStore.data?.content;
    const totalPersons = internalPersonsStore.data?.totalElements;

    useEffect(() => {
        dispatch(getPersons({ path: { page: 0, size: 25 } }, true));
    }, [dispatch]);

    useEffect(() => {
        if (!isFullyMountedRef.current) {
            isFullyMountedRef.current = true;
            return;
        }

        dispatch(clearPersons());
        let query =
            buildQueryForTableFilters(filters, "adminEditClusters") || {};
        if (sort) query.sort = sort;

        dispatch(
            getPersons(
                {
                    path: {
                        query: query,
                        page: pagination.page,
                        size: pagination?.size ? pagination.size : 25,
                    },
                },
                true,
            ),
        );
    }, [pagination, filters, isFullyMountedRef]);

    const handleChangeRowsPerPage = event => {
        localStorage.setItem(
            "adminEditInternalPersonsRows",
            event.target.value,
        );
        setPagination({ page: 0, size: event.target.value });
    };

    const handleSortChange = useCallback(
        (update: *) => {
            setPagination({
                page: 0,
                size: pagination?.size ? pagination.size : 25,
            });
            setSort(update);
        },
        [setPagination, setSort],
    );

    const handleEdit = (person: *) => {
        setSelectedPerson(person);
        setShowEditInternalPersonModal(true);
    };

    return (
        <Fragment>
            <InternalPersonsModal
                id={`${id}-mdl-edit-internal-person`}
                isOpen={showEditInternalPersonModal}
                title="Interne persoon aanpassen"
                onClose={() => setShowEditInternalPersonModal(false)}
                person={selectedPerson}
            />

            <Typography type="headline5">Interne personen</Typography>
            <Box mt={5}>
                <Table
                    color="primary"
                    id={`${id}-cluster-members-table`}
                    loading={internalPersonsStore.loading}
                    rows={persons || []}
                    noData="Geen interne personen gevonden."
                    definition={editInternalPersonsDefinition}
                    type="adminEditInternalPersons"
                    rowRenderer={ROWRENDERERCONST.EDIT_INTERNAL_PERSONS}
                    sort={sort}
                    onSort={handleSortChange}
                    activeFilters={filters?.tableFilters}
                    onFilter={handleTableFiltersChange}
                    page={pagination.page}
                    onChangePage={(event, page) =>
                        setPagination({ ...pagination, page })
                    }
                    rowsPerPage={pagination.size}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    totalRows={totalPersons || 0}
                    onEdit={(item: *) => handleEdit(item)}
                />
            </Box>
        </Fragment>
    );
};

export default InternalPersons;
