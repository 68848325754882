//@flow
import style from "./style.module.scss";

import React, { Fragment } from "react";
import { Container } from "@material-ui/core";
import { Tabs, PageTitle } from "@components/Shared";

const findActiveTabIndex = (tabs: *) => {
    if (!tabs || tabs.length <= 1) return 0;

    let currentUrlPart = window.location.pathname.split("/").pop();
    let activeIndex = tabs.findIndex(tab =>
        currentUrlPart.includes(tab.url.split("/").pop()),
    );
    return activeIndex !== -1 ? activeIndex : 0;
};

/**
 * component
 */
const AdminUserManagementLayout = ({ children, props }: *) => {
    const tabs = [
        {
            label: "Interne groepen",
            url: "/beheer/gebruikers/interne-groepen",
        },
        {
            label: "Interne personen",
            url: "/beheer/gebruikers/interne-personen",
        },
        {
            label: "Regioverdeling",
            url: "/beheer/gebruikers/regioverdeling",
        },
        {
            label: "Clusters",
            url: "/beheer/gebruikers/clusters",
        },
        {
            label: "Externe groepen",
            url: "/beheer/gebruikers/externe-groepen",
        },
        {
            label: "Externe personen",
            url: "/beheer/gebruikers/externe-personen",
        },
    ];
    return (
        <Fragment>
            <PageTitle title={"Gebruikers beheer"}>
                {tabs && tabs.length > 0 && (
                    <Tabs
                        items={tabs}
                        activeTab={findActiveTabIndex(tabs)}
                        noScroll
                        id={"main-tabs"}
                        controlled
                    />
                )}
            </PageTitle>
            <Container maxWidth="xl" className={style.contentContainer}>
                {React.Children.map(children, child =>
                    React.cloneElement(child, {
                        props,
                    }),
                )}
            </Container>
        </Fragment>
    );
};

export default AdminUserManagementLayout;
