//@flow
import style from "../style.module.scss";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import {
    TableRow,
    TableCell,
    TextField,
    Box,
    Typography,
    IconButton,
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Cancel as CancelIcon,
} from "@material-ui/icons";
import { Grow } from "@material-ui/core";
import { updatePerson } from "@stores/update-person";

type Props = {
    id: string,
    record: *,
    index: number,
    action?: *,
    callbacks: *,
};

/**
 * AdminEditInternalPersonsRow
 */
const AdminEditInternalPersonsRow = ({
    record,
    index,
    id,
}: // callbacks,
Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [callName, setCallName] = useState(record.callName);
    const [originalCallName, setOriginalCallName] = useState(record.callName);
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useDispatch();

    const handleEditClick = () => {
        setOriginalCallName(callName);
        setIsEditing(true);
    };

    const handleSaveClick = event => {
        event.stopPropagation();
        if (callName !== originalCallName) {
            dispatch(updatePerson(record.id, { callName }));
        }
        setIsEditing(false);
    };

    const handleCancelClick = event => {
        event.stopPropagation();
        setCallName(originalCallName);
        setIsEditing(false);
    };

    const handleCallNameChange = event => {
        setCallName(event.target.value);
    };

    const renderLabels = array => {
        if (array) {
            return (
                <Box display="flex" flexDirection="column" flexWrap="wrap">
                    {array.map((item, index) => (
                        <Typography
                            key={item.id}
                            type="subtitle1Regular"
                            noWrap
                        >
                            {item.name}
                            {index < array.length - 1 && ","}
                        </Typography>
                    ))}
                </Box>
            );
        }
        return null;
    };

    return (
        <TableRow
            id={id}
            key={index}
            className={classNames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell align="left">{record.firstName}</TableCell>
            <TableCell
                align="left"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleEditClick}
                style={{ position: "relative" }}
            >
                {isEditing ? (
                    <Grow in={isEditing}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                value={callName}
                                onChange={handleCallNameChange}
                                onClick={e => e.stopPropagation()}
                            />
                            <IconButton size="small" onClick={handleSaveClick}>
                                <SaveIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={handleCancelClick}
                            >
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Grow>
                ) : (
                    <Typography>{callName}</Typography>
                )}
                {!isEditing && isHovered && (
                    <IconButton
                        size="small"
                        onClick={handleEditClick}
                        style={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                        }}
                    >
                        <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                )}
            </TableCell>
            <TableCell align="left">{record.lastName}</TableCell>
            <TableCell align="left">{record.emailAddress}</TableCell>
            <TableCell align="left">{renderLabels(record.groups)}</TableCell>
            <TableCell align="left">{renderLabels(record.clusters)}</TableCell>
        </TableRow>
    );
};

export default AdminEditInternalPersonsRow;
