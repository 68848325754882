// @flow

import style from "./style.module.scss";

// libs
import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

// own
import { Typography } from "@components/Shared";
import Table from "@components/Shared/Table";

import { clusters as clustersDefinition } from "../../../definitions";
import { ROWRENDERERCONST } from "../../../constants";

/**
 * Props type
 */
type Props = {
    id: string,
    data: *,
    currentMembers: *,
    onEditClusterName: (ev: *) => void,
    onEditClusterMembers: (ev: *) => void,
};

/**
 * AdminClustersForm
 */
const AdminClustersForm = ({
    id,
    data,
    currentMembers,
    onEditClusterName,
    onEditClusterMembers,
}: Props) => {
    return (
        <Fragment>
            <Paper className={style.wrapper} style={{ minWidth: "54rem" }}>
                <Typography type="headline5">Clustergegevens</Typography>
                <Box display="flex" mt={3}>
                    <Typography
                        type="headline6"
                        id={`${id}-name`}
                        margin="dense"
                    >
                        {data.name}
                    </Typography>
                    <IconButton
                        id={`${id}-button-edit`}
                        size="small"
                        color="primary"
                        aria-label="bewerken"
                        onClick={onEditClusterName}
                        type="submit"
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
            </Paper>
            {data.id && (
                <Paper className={style.wrapper}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography type="headline5">Inspecteurs</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onEditClusterMembers}
                        >
                            Cluster Beheren
                        </Button>
                    </Box>
                    <Box mt={3} style={{ minWidth: "54rem" }}>
                        <Table
                            color="primary"
                            id={id}
                            rows={currentMembers || []}
                            noData="Geen inspecteurs toegewezen aan deze cluster."
                            definition={clustersDefinition}
                            type="clusters"
                            rowRenderer={ROWRENDERERCONST.CLUSTERS}
                            hidePagination
                        />
                    </Box>
                </Paper>
            )}
        </Fragment>
    );
};

export default AdminClustersForm;
